.canvas-wrap { position:relative } 

.overlay {
  position:absolute;
  z-index: 1;
}
.heaven
{
  position:absolute;
  z-index: -1;
  
}


