
.boton{
    width:23px;
    height:24px;
    background-color:#5C7BF0;
    margin: -2px;
    padding:2.5px;
    border: none; 
    border-radius: 50px;
  }

  .boton:hover{
    opacity: 0.50;
    -moz-opacity: .50;
    filter:alpha (opacity=50);
  }
  
  .title{
    width: 75;
    display:flex;
    margin: 0px;

  }

  .div{      
    border-style: groove;
    border-width: thin;
    border-color: whitesmoke;
    border-top-width: 5px;
    border-bottom-width: 5px;
    font-size:10px;
    text-align:justify;
    width:auto;
    display:flexbox;
    height:50rem;
  }

  .divAccionista{
    padding-top: 10px;
    padding-bottom: -10px;
    float: table-row;
    border-style: none;
    margin: 4px;    
  }

  .porcentaje{
    border-style: groove ;
    border-width: thin;
    border-color: whitesmoke;
    border-top-width: 5px;
    border-left-width: 0px;
    border-bottom-width: 5px;
    font-size:12px; 
    text-align:justify;
    width:150px;
    display:table-cell;
  }

  .totalImport{
    border-style: groove ;
    border-width: thin;
    border-color: whitesmoke;
    border-top-width: 5px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 5px;
    font-size:12px; 
    text-align:justify;
    width:150px;
    display:table-cell;
  }

  .subDivName{
    padding-top:5px;
    padding-bottom:20px;
    font-size:12px; 
    display: flexbox;
    text-align:center;
    width:auto;

  }
  
  .subDivAccion{
    padding-top:5px;
    width: auto;
    height: auto;
    padding-bottom:20px;
    font-size:14px; 
    display: flexbox;
    text-align:center;
  }
  .subDivTotales{
    padding-top:20px;
    font-size:14px; 
    padding-bottom:20px;
    text-align:center;
  }

  .subDivInfo{
    text-align:center;
  }

  .contenedorG {
    margin: 2rem auto;
    border: 1px solid #aaa;
    background: #f1f2f3;
    overflow-y:scroll;
    overflow-x:hidden;
    box-sizing: border-box;
    
}


.contenedorD::-webkit-scrollbar {
  width: 15px;     
    height: 15px; 
    -webkit-appearance: none;
}

.contenedorD:-webkit-scrollbar:vertical {
    width:3px;
}

.contenedorD::-webkit-scrollbar-button:increment,.contenedorD::-webkit-scrollbar-button {
    display: none;
} 

.contenedorD::-webkit-scrollbar:horizontal {
    height: 3px;
}

.contenedorD::-webkit-scrollbar-thumb {
    background-color: #5C7BF0;
    border-radius: 10px;
    border: 2px solid #f1f2f3;
}

.contenedorD::-webkit-scrollbar-track {
    border-radius: 10px;  
}

.blocker {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width:100%;  
  z-index: 50;       
  background: rgba(72, 71, 71, 0.5) repeat;  
  }
  .popup{
    z-index: 100;   
    margin-top: 400px;      
    display: flex;
    align-items: center;
    justify-content: center;
    }



 